<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { ref, onMounted } from 'vue'
import { endSpinner, startSpinner } from '@/components/layout/GlobalSpinner.vue'
import { useRouter } from 'vue-router'
import { simpleError } from '@/components/shared/SimpleError.vue'
import SquareRadioItem from '@/components/form/radio/SquareRadioItem.vue'
import type { IPatient, IProxyItem } from '@/Interfaces'
import { useAuthStore } from '@/stores/AuthStore'
import { simpleMessage } from '@/components/dialogs/SimpleMessageDialog.vue'
import { useI18n } from 'vue-i18n'
import { sentryException } from '@/utils/sentry'
import FunctionDialog from '@/components/dialogs/FunctionDialog.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import PageError from '@/components/shared/PageError.vue'
import api from '@/api'

const router = useRouter()
const authStore = useAuthStore()
const { t } = useI18n()
const proxies = ref<IProxyItem[]>([])
const selectedCPR = ref<undefined | number>()

const loading = ref(false)
const fetchingError = ref(false)
const fetchingProxies = ref(true)

const handleValidate = async () => {
  if (!selectedCPR.value) {
    return
  }

  try {
    loading.value = true

    // The API accepts a clean CPR number.
    const cleanCPR = selectedCPR.value.toString().replace('-', '')

    // Confirming the proxy CPR

    const response = await api.user.setProxyPatient(cleanCPR)

    loading.value = false

    // Setting the proxy data
    if (response.status === 200) {
      const proxy: IPatient = await response.json()
      await authStore.setProxy(proxy)
      authStore.setLoginStep('parent:logged')
      return simpleMessage(
        t('onboarding.powerOfAttorneryAlert.title'),
        t('onboarding.powerOfAttorneryAlert.description', [proxy.fullName])
      )
    }

    // It is an inactive user.
    if (response.status === 401) {
      authStore.setLoginStep('inactive')
      return
    }

    // 404 means there is not child account yet. When this happend, we should
    // ask to users if they want to create a new account for the child. The
    // question modal uses the CPR number.
    if (response.status === 404) {
      authStore.setLoginStep('parent:no_proxy_found', cleanCPR)
      return
    }

    // If there is any permission error we should show the message
    if (response.status === 403) {
      return simpleMessage(null, t('onboarding.cprNumberIsUnauthorized'))
    }

    simpleError('global.anErrorOccurred')
  } catch (error) {
    loading.value = false
    sentryException(error)
    simpleError('global.anErrorOccurred')
  }
}

const handleLogout = async () => {
  try {
    startSpinner()
    api.user.logout()
    authStore.logout()
    router.push('/login')
    endSpinner()
  } catch (error) {
    endSpinner()
    simpleError('global.anErrorOccurred')
  }
}

onMounted(async () => {
  try {
    startSpinner()
    fetchingError.value = false
    const response = await api.user.getProxyPatients()
    fetchingProxies.value = false
    endSpinner()

    if (response.status === 200) {
      proxies.value = (await response.json()) as IProxyItem[]
      if (proxies.value.length === 0) {
        authStore.setLoginStep('parent:no_rights')
      }
      return
    } else if (response.status === 404) {
      authStore.setLoginStep('parent:no_rights')
      return
    }

    fetchingError.value = true
    const data = await response.json()
    sentryException(new Error('API Error: user/getproxypatients'), { data })
  } catch (error) {
    endSpinner()
    fetchingProxies.value = false
  }
})

const maskedCPR = (cpr: number): string | null => {
  if (!cpr) {
    return null
  }

  const text = cpr.toString()

  return `${text.substring(0, 6)}-${text.substring(6)}`
}
</script>

<template>
  <FunctionDialog
    :open="true"
    :is-closable="false"
    backdrop-type="blur"
    :title="$t('onboarding.custodyForMinors')"
    :description="$t('onboarding.custodyDescription')"
  >
    <PageError :error="fetchingError">
      <div class="h300">{{ $t('onboarding.yourPowerOfAttorneys') }}</div>
      <div class="b300 text-neutral800">
        {{ $t('onboarding.selectCitizen') }}
      </div>

      <div class="mt-16 flex flex-col gap-2 pb-3">
        <div v-if="fetchingProxies" class="text-center h300">
          {{ $t('global.loading') }}
        </div>
        <SquareRadioItem
          v-for="(item, index) of proxies"
          :key="index"
          :value="item.cpr"
          :model="selectedCPR"
          class="w-full"
          reverse
          @update="() => (selectedCPR = item.cpr)"
        >
          <div>
            <div class="h300">{{ item.name }}</div>
            <div class="b300 text-neutral700">
              {{ $t('profile.cpr') }}: {{ maskedCPR(item.cpr) }}
            </div>
          </div>
        </SquareRadioItem>
      </div>
    </PageError>

    <template v-slot:actions>
      <VerticalLayout>
        <ButtonComponent
          type="primary"
          size="large"
          text="onboarding.logIn"
          full-width
          gradient
          :loading="loading"
          :disabled="!selectedCPR || loading"
          @click="handleValidate"
        />
        <ButtonComponent
          type="secondary"
          size="large"
          text="onboarding.logOut"
          full-width
          gradient
          :disabled="loading"
          @click="handleLogout"
        />
      </VerticalLayout>
    </template>
  </FunctionDialog>
</template>
