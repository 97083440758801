<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { fromMarkdownToHTML } from '@/utils/strings'
import { useAuthStore } from '@/stores/AuthStore'
import { useRouter } from 'vue-router'
import api from '@/api'
import { endSpinner, startSpinner } from '@/components/layout/GlobalSpinner.vue'
import type { IPatient } from '@/Interfaces'
import { simpleMessage } from '@/components/dialogs/SimpleMessageDialog.vue'
import { simpleError } from '@/components/shared/SimpleError.vue'
import { sentryException } from '@/utils/sentry'

const { t } = useI18n()
const authStore = useAuthStore()
const router = useRouter()

const handleClose = () => {
  authStore.logout()
  router.push('/login')
}

const handleCreateNewUser = async () => {
  try {
    startSpinner()

    // Creating a new user
    const response = await api.user.setProxyPatient(
      authStore.auth.possibleProxyCPR || '',
      'create'
    )

    endSpinner()

    // Setting the proxy data
    if (response.status === 200) {
      const proxy: IPatient = await response.json()
      await authStore.setProxy(proxy)
      authStore.setLoginStep('relative:logged')
      return simpleMessage(
        t('onboarding.powerOfAttorneryAlert.title'),
        t('onboarding.powerOfAttorneryAlert.description', [proxy.fullName])
      )
    }

    // It is an inactive user.
    if (response.status === 401) {
      authStore.setLoginStep('inactive')
      return
    }

    // If there is any permission error we should show the message
    if (response.status === 403) {
      return simpleMessage(null, t('onboarding.cprNumberIsUnauthorized'))
    }

    simpleError('global.anErrorOccurred')
  } catch (error) {
    endSpinner()
    sentryException(error)
    simpleError('global.anErrorOccurred')
  }
}

const description = computed(() => {
  const value = t('onboarding.missingProfile.guardianDescription')
  return fromMarkdownToHTML(value).replace(/\\n/g, '<br>')
})
</script>

<template>
  <BaseDialog
    :open="authStore.auth.loginStep === 'relative:no_proxy_found'"
    backdrop-type="gray"
  >
    <div
      class="fixed top-0 left-0 bg-mango50 w-screen h-screen desktop:w-540 desktop:unset-position desktop:unset-height desktop:bg-neutral0"
    >
      <div
        class="flex flex-col justify-between h-screen desktop:block desktop:unset-height p-20"
      >
        <div class="h-full flex flex-col justify-center">
          <div class="flex justify-center mb-20">
            <div
              class="bg-neutral0 drop-shadow-sm w-120 h-120 rounded-full flex justify-center items-center desktop:bg-darkOcean100 desktop:w-60 desktop:h-60"
            >
              <fa-icon
                :icon="['fas', 'user-plus']"
                class="text-3xl desktop:text-xl"
              />
            </div>
          </div>
          <div class="h700 text-center mb-3">
            {{ $t('onboarding.missingProfile.title') }}
          </div>
          <div class="b300 text-center mb-24" v-html="description"></div>
        </div>

        <VerticalLayout>
          <ButtonComponent
            type="primary"
            size="large"
            text="onboarding.missingProfile.createProfile"
            full-width
            gradient
            @click="handleCreateNewUser"
          />
          <ButtonComponent
            type="secondary"
            size="large"
            text="global.close"
            full-width
            gradient
            @click="handleClose"
          />
        </VerticalLayout>
      </div>
    </div>
  </BaseDialog>
</template>
