<script setup lang="ts">
import { GlobalEvents } from '@/Enums'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import ButtonComponent from './ButtonComponent.vue'
import AlertDialog from '@/components/dialogs/AlertDialog.vue'

const message = ref<null | string>(null)
const translationValues = ref<any>(undefined)

const onMessage = (event: any) => {
  message.value = event.message
  translationValues.value = event.translationValues
}

onMounted(() => {
  document.addEventListener(GlobalEvents.SimpleErrorMessage, onMessage)
})

onBeforeUnmount(() => {
  document.removeEventListener(GlobalEvents.SimpleErrorMessage, onMessage)
})
</script>

<script lang="ts">
import ErrorEvent from '@/utils/extends/ErrorEvent'

export const simpleError = (message: string, translationValues?: any) =>
  document.dispatchEvent(
    new ErrorEvent(
      GlobalEvents.SimpleErrorMessage,
      message,
      translationValues || {}
    )
  )
</script>

<template>
  <AlertDialog
    v-if="message"
    :is-open="!!message"
    type="error"
    title-key="global.anErrorOccurred"
    :description-key="message"
    :description-params="translationValues"
  >
    <div>
      <ButtonComponent
        type="secondary"
        size="medium"
        text="global.ok"
        full-width
        @click="message = null"
      />
    </div>
  </AlertDialog>
</template>
