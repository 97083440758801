<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { useGeneralStore } from '@/stores/GeneralStore'
import FormDivider from '@/components/form/FormDivider.vue'
import { ref, onMounted } from 'vue'
import { endSpinner, startSpinner } from '@/components/layout/GlobalSpinner.vue'
import { useRouter } from 'vue-router'
import { simpleError } from '@/components/shared/SimpleError.vue'
import { useAuthStore } from '@/stores/AuthStore'
import type { IPatient, IProxyItem } from '@/Interfaces'
import { simpleMessage } from '@/components/dialogs/SimpleMessageDialog.vue'
import { useI18n } from 'vue-i18n'
import { sentryException } from '@/utils/sentry'
import FunctionDialog from '@/components/dialogs/FunctionDialog.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import api from '@/api'
import SquareRadioItem from '@/components/form/radio/SquareRadioItem.vue'

const router = useRouter()
const generalStore = useGeneralStore()
const authStore = useAuthStore()
const { t } = useI18n()

const loading = ref(false)
const error = ref<undefined | string>()
const fetchingError = ref(false)
const fetchingProxies = ref(true)
const noPowersOfAttorney = ref(false)
const proxies = ref<IProxyItem[]>([])
const selectedCPR = ref<undefined | number>()

const handleValidate = async () => {
  const cleanCPR = selectedCPR.value?.toString().replace('-', '')
  if (!cleanCPR) {
    return
  }

  try {
    error.value = undefined
    loading.value = true
    const response = await api.user.setProxyPatient(cleanCPR)
    loading.value = false

    // Setting the proxy data
    if (response.status === 200) {
      const proxy: IPatient = await response.json()
      await authStore.setProxy(proxy)
      authStore.setLoginStep('relative:logged')
      return simpleMessage(
        t('onboarding.powerOfAttorneryAlert.title'),
        t('onboarding.powerOfAttorneryAlert.description', [proxy.fullName])
      )
    }

    // 404 means there is not child account yet. When this happend, we should
    // ask to users if they want to create a new account for the child.
    if (response.status === 404) {
      // The question modal should use the CPR number.
      authStore.setLoginStep('relative:no_proxy_found', cleanCPR)
      return
    }

    // If there is any permission error we should show the message
    if (response.status === 403) {
      error.value = t('onboarding.cprNumberIsUnauthorized')
      return
    }

    error.value = t('global.anErrorOccurred')
  } catch (err) {
    loading.value = false
    sentryException(err)
    simpleError('global.anErrorOccurred')
  }
}

const handleLogout = async () => {
  try {
    startSpinner()
    await api.user.logout()
    authStore.logout()
    router.push('/login')
    endSpinner()
  } catch (error) {
    endSpinner()
    simpleError('global.anErrorOccurred')
  }
}

onMounted(async () => {
  try {
    startSpinner()
    fetchingError.value = false
    noPowersOfAttorney.value = false
    const response = await api.user.getProxyPatients()
    fetchingProxies.value = false
    endSpinner()
    if (response.status === 200) {
      proxies.value = (await response.json()) as IProxyItem[]
      if (proxies.value.length === 0) {
        noPowersOfAttorney.value = true
      }

      return
    } else if (response.status === 404) {
      noPowersOfAttorney.value = true
      return
    } else if (response.status === 401) {
      // Clear tokens
      authStore.logout()
      // Redirect to the login
      window.router.push('/login')
      return
    }
    fetchingError.value = true
    const data = await response.json()
    sentryException(new Error('API Error: user/getproxypatients'), { data })
  } catch (error) {
    endSpinner()
    fetchingProxies.value = false
    fetchingError.value = true
    sentryException(error)
  }
})

const maskedCPR = (cpr: number): string | null => {
  if (!cpr) {
    return null
  }

  const text = cpr.toString()

  return `${text.substring(0, 6)}-${text.substring(6)}`
}
</script>

<template>
  <FunctionDialog
    :open="true"
    :is-closable="false"
    backdrop-type="blur"
    :title="$t('onboarding.accessForRelatives')"
    :description="$t('onboarding.custodyDescription')"
  >
    <FormDivider type="medium" v-if="generalStore.breakpoint === 'mobile'" />

    <div class="flex flex-col gap-2 pb-3">
      <div v-if="fetchingProxies" class="text-center h300">
        {{ $t('global.loading') }}
      </div>
      <div v-else>
        <div class="pb-16">
          <div class="h300 text-neutral1000">
            {{ $t('onboarding.yourPowerOfAttorneys') }}
          </div>
          <div class="b300 text-neutral1000">
            {{ $t('onboarding.selectCitizen') }}
          </div>
        </div>
        <SquareRadioItem
          v-for="(item, index) of proxies"
          :key="index"
          :value="item.cpr"
          :model="selectedCPR"
          class="w-full"
          reverse
          @update="() => (selectedCPR = item.cpr)"
        >
          <div>
            <div class="h300">{{ item.name }}</div>
            <div class="b300 text-neutral700">
              {{ $t('profile.cpr') }}: {{ maskedCPR(item.cpr) }}
            </div>
          </div>
        </SquareRadioItem>
      </div>
    </div>

    <div v-if="noPowersOfAttorney" class="pb-32">
      <div class="h300 text-neutral1000">
        {{ $t('onboarding.powerOfAttorneryEmpty.title') }}
      </div>
      <div class="b300 text-neutral1000">
        {{ $t('onboarding.powerOfAttorneryEmpty.description') }}
      </div>
    </div>

    <div class="flex justify-center mb-2">
      <a
        href="https://fuldmagt.nemlog-in.dk/"
        target="_blank"
        rel="noreferrer noopener"
        class="h500 text-darkOcean600 flex gap-2 items-center cursor-pointer focusVisible px-3 py-1 focus:outline-none"
      >
        <fa-icon :icon="['far', 'file-signature']" />
        {{ $t('onboarding.requestPowerOfAttorney') }}</a
      >
    </div>
    <template v-slot:actions>
      <VerticalLayout>
        <ButtonComponent
          type="primary"
          size="large"
          text="onboarding.logIn"
          full-width
          gradient
          :loading="loading"
          :disabled="selectedCPR === undefined"
          @click="handleValidate"
        />
        <ButtonComponent
          type="secondary"
          size="large"
          text="onboarding.logOut"
          full-width
          gradient
          :disabled="loading"
          @click="handleLogout"
        />
      </VerticalLayout>
    </template>
  </FunctionDialog>
</template>
