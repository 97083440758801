class ErrorEvent extends Event {
  public message: string
  public translationValues: any

  constructor(type: string, message: string, translationValues: any) {
    super(type)
    this.message = message
    this.translationValues = translationValues
  }
}

export default ErrorEvent
