import { API_SERVER, IBG_API_URL } from '@/Constants'
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core'
import { RestLink } from 'apollo-link-rest'
import SessionManager from './session'
import type { IAuth } from '@/Interfaces'
import { useAuthStore } from '@/stores/AuthStore'
import { simpleError } from '@/components/shared/SimpleError.vue'
import { sentryException } from './sentry'

export const IS_PROD = import.meta.env.PROD

const authStorage = new SessionManager<IAuth>('auth')

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => {
    const auth = authStorage.get()
    const token = auth?.token || ''
    return {
      headers: {
        ...headers,
        Accept: 'application/json',
        Authorization: token,
      },
    }
  })
  return forward(operation)
})

// Set `RestLink` with your endpoint
const restLink = new RestLink({
  uri: `${API_SERVER}/`,
})

// Cache implementation
const cache = new InMemoryCache()

const customFetch = async (
  uri: URL | RequestInfo,
  options: RequestInit | undefined
) => {
  const store = useAuthStore()
  const headers: HeadersInit = new Headers()
  headers.set('Authorization', store.auth.ibgToken || '')

  const response = await window.originalFetch(uri, {
    ...options,
    headers,
  })

  if (response.status >= 400) {
    simpleError('global.anErrorOccurred')
    sentryException(new Error('GraphQL Error'), { body: await response.text() })
  }

  return response
}

// Create the graphql client
const graphqlClient = new ApolloClient({
  cache,
  link: createHttpLink({
    uri: IBG_API_URL,
    fetch: customFetch,
  }),
})

// Create the rest client
const restClient = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([authRestLink, restLink]),
})

export default { graphqlClient, restClient }
